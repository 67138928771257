import axios from "axios";
import {Action, getModule, Module, Mutation, VuexModule} from "vuex-module-decorators";
import {TaskThread} from "../models/interfaces/TaskThread";
import store from "../store";

/**
 * @summary タスク情報に関するStoreを提供します.
 */
@Module({dynamic: true, store, name: "taskThread", namespaced: true})
export class TaskThreadStore extends VuexModule {
    // #region private fields
    private _tasksThreads: TaskThread[] = [];
    // #endregion

    // #region public getters
    /**
     * @summary タスクリスト
     */
    public get taskThreads(): TaskThread[] {
        return this._tasksThreads;
    }
    // #endregion

    // #region public mutations
    /**
     * @summary タスクリストをセットします.
     * @param tasks タスクリスト
     */
    @Mutation
    public setTaskThreads(tasks: TaskThread[]): void {
        this._tasksThreads = tasks;
    }
    // #endregion

    // #region actions
    /**
     * @summary タスク情報をサーバーから取り込みます.
     */
    @Action
    public async fetchTaskThreads(taskId: number): Promise<void> {
        try {
            this.setTaskThreads([]);
            const response = await axios.get<TaskThread[]>("api/task_thread/?taskId=" + taskId);
            this.setTaskThreads(response.data);
        }
        catch (ex) {
            console.error("タスクスレッドの取り込みに失敗しました.");
        }
    }

    /**
     * @summary タスク情報を追加します.
     * @param newTask 追加するタスク情報
     */
    @Action
    public async addTaskThread(taskThread: TaskThread): Promise<boolean> {
        try {
            const response = await axios.post("api/task_thread", taskThread);
            this._tasksThreads.push(response.data);
            return true;
        }
        catch {
            console.error("タスクスレッドの追加に失敗しました。");
            return false;
        }
    }

    /**
     * @summary タスク情報を保存します.
     * @param task 保存するタスク情報
     */
    @Action
    public async editTaskThreadText(data: TaskThread): Promise<boolean> {
        try {
            const response = await axios.put("api/task_thread/" + data.taskThreadId, data);
            return true;
        }
        catch {
            console.error("タスクの保存に失敗しました.");
            return false;
        }
    }

    /**
     * @summary タスク情報を削除します.
     * @param task 削除するタスク情報
     */
    @Action
    public async delete(threadId: number): Promise<boolean> {
        const response = await axios.delete("api/task_thread/" + threadId);
        if (response.data && response.data.result) {
            return true;
        }
        else {
            return false;
        }
    }
    // #endregion
}

export const TaskStoreModule = getModule(TaskThreadStore);
