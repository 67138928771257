import {GanttChart} from "./interfaces/GanttChart";
import {Task} from "./interfaces/Task";

/**
 * @summary タスク情報を提供します.
 */
export class TaskModel implements Task, GanttChart {
    public sheetId = 0;
    public personId = 1;
    public endDate: Date = new Date();
    public id = 0;
    public statusId = 0;
    public systemId = 1;
    public category = "";
    public detail = "";
    public priority = 1;
    public cost = 1;
    public optionHolidayCount = 0;
    public developStartDate = "";
    public developEndDate = "";
    public releaseDate = "";
    public description = "";
    public specification = "";

    public startDate?: Date = undefined;
    public completeDate?: Date = undefined;
    public color = "";
    public threadCount = 0;

    /**
     * @summary コンストラクタ
     * @param task タスクデータ
     * @param gantChart ガントチャートデータ
     */
    public constructor(task: Partial<Task>, gantChart: Partial<GanttChart>) {
        Object.assign(this, task);
        Object.assign(this, gantChart);
    }
}
